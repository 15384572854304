<template>
  <div class="bg-white">
    <header-image class="header-height mb-0 mx-0">
      <img src="@/assets/img/integrations/integrations.jpg" alt="Header picture" class="relative rounded-0" width="auto" height="auto"/>
      <h1 class="header-text">{{ $t('integrations.integrations_types') }}</h1>
    </header-image>
    
    <section class="d-md-flex">
      <div class="col-12 col-md-6 padding-center">
      <img
        loading="lazy"
        src="@/assets/img/integrations/WordPress-Logo.png"
        alt="slider image"  width="180" height="100"
      />
      <p class="pt-4">{{ $t('integrations.wordpress') }}</p>
    </div>

    <div class="col-12 col-md-6 padding-center">  
      <img
        loading="lazy"
        src="@/assets/img/integrations/google-calendar.png"
        alt="slider image"  width="120" height="100"
      />
        <p class="pt-4">{{ $t('integrations.googlecalendar') }}</p>
      </div>
    </section>

    <section class="d-md-flex">
      <div class="col-12 col-md-6 padding-center">
      <img
        loading="lazy"
        src="@/assets/img/integrations/altiria-logo.png"
        alt="slider image"  width="200" height="100"
      />
      <p class="pt-4">{{ $t('integrations.altiria') }}</p>
    </div>

    <div class="col-12 col-md-6 padding-center">  
      <img
        loading="lazy"
        src="@/assets/img/integrations/api-logo.png"
        alt="slider image"  width="100" height="100"
      />
        <p class="pt-4" v-html="$t('integrations.api')"></p>
      </div>
    </section>

    <section class="d-md-flex pb-5">
      <div class="col-12 col-md-6 padding-center">
        <img
          loading="lazy"
          src="@/assets/img/integrations/Firebase_Logo.png"
          alt="slider image"  width="200" height="100"
        />
        <p class="pt-4" v-html="$t('integrations.firebase')"></p>
      </div>
      <div class="col-12 col-md-6 padding-center"></div>
    </section>
   
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  HeaderImage
} from "@/components/index";

export default {
  data() {
    return {
    }
  },
  components: {
    HeaderImage
  },
  computed: { ...mapState(["defaultTitle"]) },
  metaInfo() {
    return {
      title: `${this.$t('vueMeta.integrations.title', [this.defaultTitle])}`,
      meta: [
        {name: 'og:title', property: 'og:title', content:  `${this.$t('vueMeta.integrations.title', [this.defaultTitle])}`},
        {name: 'og:type', property: 'og:type', content: 'website'},
        {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}`},
        {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.integrations.description')},
        {name: 'description', content: this.$t('vueMeta.integrations.description')},
        {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME}
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/white-dashboard.scss';
  section {
    h2, h3, p {
      color: black!important;
      display: block;
      font-family: $font-family-base;
    }
    h2 {
      font-weight: bolder;
      font-size: 2.8rem;
      margin-bottom: 0;
    }
    h3 {
      font-weight: bolder;
      font-size: 7rem;
      margin-bottom: 0;
    }
    p {
      font-weight: 400;
      font-size: 0.9rem;
      &.textIcon{
        font-size: 0.75rem;
      }
    }
    .bg-white {
      background: white;
    }
    .padding-center {
      padding: 2rem 2rem 0rem 2rem;
      margin: auto;
    }
    
    &.padding-center {
      padding: 2rem 2rem 0rem 2rem;
      margin: auto;
    }
  }
  .bold {
    font-weight: bold;
  }
  .icon {
    width: 5.5rem;
    height: 5.5rem;
  }

  .container-number-color {
    span {
      color: $secondary;
      font-size: 1.5rem;
      font-weight: bold;
    }
    p {
      font-size: 0.8rem;
    }
  }

  .section_5 {
    max-width: 1000px;
  }
  @media (min-width: 576px) {
    .container-icons {
      width: 45%;
    }
  }

  @media (min-width: 768px) {
    .margin-center {
      margin-left: 6rem!important;
      margin-right: 6rem!important;
    }
    section {
      .padding-center {
        padding: 2rem 4rem 0rem 4rem;
        margin: auto;
      }
      &.padding-center {
        padding: 8rem;
        margin: auto;
      }
    } 
    .container-icons {
      width: 20%;
    }
  }
</style>
<style lang="scss">
@import '../../assets/scss/white-dashboard.scss';
.header-text, .footer-text-1, .footer-text-2 {
  color: white!important;
  font-family: $font-family-base;
  font-size: 2rem!important;
  font-weight: 300;
  position: absolute;
  text-align: center;
  width: 100%;
}
.header-text {
  top: 15vh;
}
.footer-text-1 {
  top: 11vh;
}
.footer-text-2 {
  top: 19vh;
}
.header-height {
  img {
    width: auto!important;
    height: 300px!important;
    object-fit: cover;
  }
  width: auto!important;
  height: 300px!important;
}
@media (min-width: 576px) {
  .header-text {
    font-size: 2.8rem;
  }
  .header-height {
    width: auto!important;
    height: auto!important;
  }
}
</style>
